import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import CapsuleCodeAssignment from "./pages/CapsuleCodeAssignment.jsx";
import CheckCapsuleByNumber from "./pages/CheckCapsuleByNumber";
import CompleteWriting from "./pages/CompleteWriting.jsx";
import CompleteWriting2 from "./pages/CompleteWriting2.jsx";
import ConfirmBasicSetting from "./pages/ConfirmBasicSetting";
import EnterURL from "./pages/EnterURL";
import { Helmet } from "react-helmet-async";
import Home from "./pages/Home";
import Login from "./pages/Login.jsx";
import Name from "./pages/Name.jsx";
import Nickname from "./pages/Nickname";
import OpenCapsule from "./pages/OpenCapsule";
import Purpose from "./pages/Purpose.jsx";
import React from "react";
import ReceivedText from "./pages/ReceivedText";
import ReceivedVoice from "./pages/ReceivedVoice";
import RecipientInput from "./pages/RecipientInput";
import Recording from "./pages/Recording";
import RollingpaperOpen from "./pages/RollingpaperOpen";
import Settings from "./pages/Settings.jsx";
import TagManager from "react-gtm-module";
import Theme from "./pages/Theme.jsx";
import URLnCodeAssignment from "./pages/URLnCodeAssignment.jsx";
import Write from "./pages/Write";
import WritingFormat from "./pages/WritingFormat";

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-K5HF8J77",
  };

  TagManager.initialize(tagManagerArgs);
  return (
    <div className="App">
      <Helmet>
        <title>RE:memory</title>
        <meta
          name="description"
          content="감정을 기억하고 선물하다, RE:memory 
    감정을 기억하고 선물할 수 있는 온라인 롤링페이퍼, 편지, 타임캡슐 서비스"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route
          path="/capsule/assign-number"
          element={<CapsuleCodeAssignment />}
        ></Route>
        <Route
          path="/capsule/assign-number-url"
          element={<URLnCodeAssignment />}
        ></Route>
        <Route
          path="/capsule/input-number"
          element={<CheckCapsuleByNumber />}
        ></Route>
        <Route
          path="/capsule/settings/confirm"
          element={<ConfirmBasicSetting />}
        ></Route>
        <Route
          path="/capsule/input-recipients"
          element={<RecipientInput />}
        ></Route>
        <Route
          path="/capsule/letter-format"
          element={<WritingFormat />}
        ></Route>
        <Route
          path="/capsule/open/rolling"
          element={<RollingpaperOpen />}
        ></Route>
        <Route path="/capsule/open/text" element={<ReceivedText />}></Route>
        <Route path="/capsule/open/voice" element={<ReceivedVoice />}></Route>

        <Route path="/rolling/:rcapsule_number" element={<EnterURL />}></Route>

        <Route path="/capsule/verify" element={<OpenCapsule />} />
        <Route path="/capsule/write/text" element={<Write />} />

        <Route path="/capsule/write/voice" element={<Recording />} />
        <Route path="/login/kakao/nickname" element={<Nickname />} />
        <Route path="/login/kakao/home" element={<Home />}></Route>
        <Route path="/login/kakao/settings" element={<Settings />}></Route>
        <Route path="/capsule/settings/theme" element={<Theme />}></Route>
        <Route path="/capsule/settings/purpose" element={<Purpose />}></Route>
        <Route path="/capsule/settings/name-date" element={<Name />}></Route>
        <Route
          path="/capsule/write/Complete"
          element={<CompleteWriting2 />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
