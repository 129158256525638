import React from "react";

export default function check_icon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
    >
      <path
        d="M21.5009 36.1199C13.4169 36.1199 6.88086 29.5839 6.88086 21.4999C6.88086 13.4159 13.4169 6.87988 21.5009 6.87988C29.5849 6.87988 36.1209 13.4159 36.1209 21.4999C36.1209 29.5839 29.5849 36.1199 21.5009 36.1199ZM21.5009 8.59988C14.3629 8.59988 8.60086 14.3619 8.60086 21.4999C8.60086 28.6379 14.3629 34.3999 21.5009 34.3999C28.6389 34.3999 34.4009 28.6379 34.4009 21.4999C34.4009 14.3619 28.6389 8.59988 21.5009 8.59988Z"
        fill={color}
      />
      <path
        d="M19.7808 27.864L12.2988 20.382L13.5028 19.178L19.7808 25.456L29.4988 15.738L30.7028 16.942L19.7808 27.864Z"
        fill={color}
      />
    </svg>
  );
}
